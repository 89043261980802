import React from "react"

const MeltingPL = () => <>
  <h2>Spawalnictwo</h2>
  <p className="bigger-text text-center"> Świadczymy najwyższej jakości usługi spawalnicze: spawanie konstrukcji
    stalowych, obiektów
    przemysłowych, hal produkcyjnych, magazynów, kontenerów i różnego rodzaju ponadgabarytowych
    konstrukcji stalowych, w zależności od potrzeb klienta.</p>
  <p className="bigger-text text-center"> Nasze usługi spawalnicze wykonujemy metodami: MIG-MAG, TIG, MMA (SMA).</p>
</>

export default MeltingPL