import React from "react"
import MeltingDE from "./melting.de"
import MeltingEN from "./melting.en"
import MeltingPL from "./melting.pl"

const MeltingContent = ({ lang }) => {
  if (lang === "de") {
    return <MeltingDE />
  } else if (lang === "en") {
    return <MeltingEN />
  }
  return <MeltingPL />
}

export default MeltingContent