import React from "react"

const MeltingEN = () => <>
  <h2>Welding</h2>
  <p className="bigger-text text-center">HIBRID Pty Ltd. provides the highest quality welding services: welding of
    steel
    structures, industrial buildings, production halls, warehouses, containers, and various
    types of oversized steel construction, depending on customer’s needs.</p>
  <p className="bigger-text text-center">We also manufacture a light steel structures such as: stackable storage
    containers
    and racking systems for the automotive and transport industry.</p>
  <p className="bigger-text text-center">We perform our welding services using the following methods:</p>
  <ul>
    <li>MIG-MAG (metal inert gas-metal activegas)</li>
    <li>TIG (tungsten-electrode inert gas)</li>
    <li>SMA/MMA (known as “arc” welding)</li>
  </ul>
</>

export default MeltingEN