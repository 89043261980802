import React from "react"

const MeltingDE = () => <>
    <h2>Schweißtechnik</h2>
    <p className="bigger-text text-center">Wir bieten ein breites Leistungsspektrum für die Schweißbranche an. Schweißen
      von
      Stahlkonstruktionen, Industrieobjekten, Hallen, Lagergebäuden, Containern, sowie
      anderen überdimensionalen Konstruktionen je nach Kundenbedarfin üblichen
      Schweißverfahren MIG/MAG, TIG, MMA.</p>
    <p>Wir stellen auch Leichtbaukonstruktionen wie Automotive-Ladungsträger oder Transport-, Regal- und Lagersysteme
      her.</p>
</>

export default MeltingDE